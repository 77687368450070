<script>
  export let category = "all";
</script>

<div class="max-w-6xl mx-auto dark:bg-neutral-950">
  <div class="flex flex-row w-3/4 justify-between">
    <div class="flex flex-col mt-2 mb-8 px-3.5 xl:px-1">
      <p class="uppercase text-neutral-500 text-xs">Explore</p>
      <div class="flex flex-row items-center gap-72">
        <h2
          class="hidden md:block text-3xl whitespace-nowrap font-bold text-neutral-600 dark:text-white capitalize w-52"
        >
          Recent {category}
        </h2>
        <h2
          class="block md:hidden text-3xl whitespace-nowrap font-bold text-neutral-600 dark:text-white capitalize w-52"
        >
          Recent
        </h2>
        <!-- <hr class="hidden sm:flex w-full z-[40] h-px border-0 bg-gray-400" /> -->
        <div
          class="flex-row text-neutral-600 dark:text-white uppercase tracking-widest text-sm gap-4 font-semibold leading-tight hidden lg:flex"
        >
          <button
            class={`${
              category === "all" ? "text-cobalt" : "text-neutral-600"
            } uppercase tracking-widest text-sm font-semibold leading-tight whitespace-nowrap hover:text-cobalt duration-150 ease-in-out transition`}
            on:click={() => (category = "all")}>All |</button
          >
          <button
            class={`${
              category === "Blog" ? "text-cobalt" : "text-neutral-600"
            } uppercase tracking-widest text-sm font-semibold leading-tight whitespace-nowrap hover:text-cobalt duration-150 ease-in-out transition`}
            on:click={() => (category = "Blog")}>Blog Posts |</button
          >
          <button
            class={`${
              category === "Publications" ? "text-cobalt" : "text-neutral-600"
            } uppercase tracking-widest text-sm font-semibold leading-tight whitespace-nowrap hover:text-cobalt duration-150 ease-in-out transition`}
            on:click={() => (category = "Publications")}>Publications |</button
          >
          <button
            class={`${
              category === "Talks" ? "text-cobalt" : "text-neutral-600"
            } uppercase tracking-widest text-sm font-semibold leading-tight whitespace-nowrap hover:text-cobalt duration-150 ease-in-out transition`}
            on:click={() => (category = "Talks")}>Talks</button
          >
        </div>
      </div>
    </div>
  </div>
</div>
{#if category === "all"}
  <div>
    <slot name="all" />
  </div>
{/if}
{#if category === "Blog"}
  <div>
    <slot name="Blog" />
  </div>
{/if}
{#if category === "Publications"}
  <div>
    <slot name="Publications" />
  </div>
{/if}
{#if category === "Talks"}
  <div>
    <slot name="Talks" />
  </div>
{/if}
